<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">文章列表</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button style="margin-left: 16px" type="primary" @click="jumpAdd">
          <a-icon type="plus-circle"/>
          增加
        </a-button>
        <a-button style="margin-left: 16px" type="danger" @click="MultipleDel">
          <a-icon type="delete"/>
          删除
        </a-button>
      </div>
    </div>
    <a-card>
        <a-input slot="extra" style="width: 200px" v-model="queryParam.q" @change="handleListChange">
          <a-icon slot="suffix" type="search" @click="blogSearch"/>
        </a-input>
      <a-table
        ref="table"
        size="default"
        @change="tableChange"
        :pagination="pagination"
        :rowKey="record => record.id"
        :columns="columns"
        :scroll="{x:1670}"
        :dataSource="tableData"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
        <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}</span>
        <template slot="name" slot-scope="text, record">
          <span><a v-if="record.ifTop===true" style="color: red">【置顶】</a>
            <a style="margin-left: 5px;color: black" @click="handleView(record)">{{text}}</a>
          </span>
        </template>

        <span slot="status" slot-scope="status">
        <template>
         <a-badge :status="status| stepsFilter" :text="status | statusFilter"/>
        </template>
      </span>
        <span slot="operation" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a v-if="record.ifTop===false" style="margin-left: 6px;color: red" @click="handleTop(record)">置顶</a>
          <a v-if="record.ifTop===true" style="margin-left: 6px;color: red" @click="cancelTop(record)">取消置顶</a>
          <a style="margin-left: 6px;" @click="handleDel(record)">删除</a>
        </template>
      </span>
      </a-table>
    </a-card>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '../../../api/service.url'

  export default {
    name: 'List',
    data () {
      return {
        treeLoadUrl: SERVICE_URLS.article.type.tree,
        sortVisible: false,
        tempIdList: [],
        queryParam: {
          tagIds: '',
          typeIds: '',
          sources: '',
          ifTop: '',
          q: '',
          status: '',
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        },
        pagination: {
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        selectedRowKeys: [],
        columns: [{
          title: '序号',
          dataIndex: 'serial',
          key: 'serial',
          scopedSlots: { customRender: 'serial' }
        },
          {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
            width: 600,
            scopedSlots: { customRender: 'name' }
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            scopedSlots: { customRender: 'status' }
          },
          {
            title: '阅读',
            dataIndex: 'viewNo',
            key: 'viewNo'
          },
          {
            title: '发布用户',
            dataIndex: 'username',
            key: 'username'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: 200,
            scopedSlots: { customRender: 'operation' }
          }
        ],
        tableData: []
      }
    },
    components: { PageLayout },
    created () {
      this.blogSearch()
  /*    this.getTagList()*/
    },
    filters: {
      stepsFilter (status) {
        const statusMap = {
          'draft': 'processing',
          'review': 'warning',
          'release': 'success',
          'notApproved': 'error'
        }
        return statusMap[status]
      },
      statusFilter (status) {
        const statusMap = {
          'draft': '草稿',
          'review': '待审核',
          'release': '已发布',
          'notApproved': '审核不通过'
        }
        return statusMap[status]
      }
    },
    methods: {
      MultipleDel () {
        this.$http(this, {
          url: SERVICE_URLS.article.list.delMore,
          data: this.tempIdList,
          success: () => {
            this.blogSearch()
          }
        })
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.tempIdList = []
        this.selectedRowKeys = selectedRowKeys
        for (let item of selectedRows) {
          this.tempIdList.push(item.id)
        }
      },
      handleListChange () {
        this.blogSearch()
      },
      blogSearch () {
        this.$http(this, {
          url: SERVICE_URLS.article.list.search,
          noTips: true,
          data: this.queryParam,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
          }
        })
      },
      handleTop (record) {
        this.$http(this, {
          url: SERVICE_URLS.article.list.top,
          params: {
            id: record.id
          },
          success: () => {
            this.blogSearch()
          }
        })
      },
      cancelTop (record) {
        this.$http(this, {
          url: SERVICE_URLS.article.list.cancel_top,
          params: {
            id: record.id
          },
          success: () => {
            this.blogSearch()
          }
        })
      },
      tableChange (pagination, filters, sorter) {
        this.queryParam.size = pagination.pageSize
        this.queryParam.number = pagination.current - 1
        if (sorter.order === 'ascend') {
          this.queryParam.direction = 'ASC'
        } else {
          this.queryParam.direction = 'DESC'
        }
        this.queryParam.order = sorter.field
        this.blogSearch()
      },
      handleDel (record) {
        let self = this
        self.$confirm({
          title: '删除记录',
          content: '是否确定删除该条数据',
          onOk () {
            self.$http(self, {
              url: SERVICE_URLS.article.list.delete,
              params: {
                id: record.id
              },
              noTips: 'true',
              success: (data) => {
                self.blogSearch()
                self.$message.success('删除成功！')
              }
            })
          },
          onCancel () {
          }
        })
      },
      jumpAdd () {
        this.$router.push(
          {
            path: '/blog/release/release',
            query: {
              type: 'add'
            }
          }
        )
      },
      handleEdit (record) {
        this.$router.push(
          {
            path: '/blog/release/release',
            query: {
              id: record.id,
              type: 'edit'
            }
          }
        )
      },
      handleView (record) {
        this.$router.push(
          {
            path: '/blog/list/detail',
            query: {
              id: record.id
            }
          }
        )
      },
      checkClick (treeNodes) {
        for (let item of treeNodes) {
          this.tempTypeIds.push(item.id)
        }
      }
    }
  }
</script>

<style scoped>

</style>